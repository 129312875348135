<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">
						일반 프로젝트 &gt; 프로젝트 찾기<br />
						<p><span class="prj_ct">{{projectCount.projectGenCount}}</span>개의 프로젝트가 있습니다.</p>
					</div>
				</div>
				<div class="sco_prj_tab">
					<div class="tab-type3">
						<ul class="tab">
							<!-- <li :class="input.pageDiv == '3' ? 'on':''"  @click="clickTab('PRJ405M01')">
								<a>공급기업 지정 프로젝트 <span class="count">{{projectCount.projectScoCount}}</span></a>
							</li> -->
							<li class="on" >
								<a>일반 프로젝트</a>
							</li>  
						</ul>
					</div>
				</div>  
			</div>
		</div>
			
		<div class="mem_container bg-white">
			<div class="mem_info_contents">
				<div class="filter pt-15">
					<div class="search_box">
						<SelectComp type="select" class="select_wrap prj_name" list="1:프로젝트명,2:수행사명" v-model="input.searchGubun"/>						
						<InputComp type="text" classNm="search_box int" :placeholder="input.searchGubun == '1' ? '프로젝트명' : '수행사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
						<div class="search_btn" @click="getList('1')"><img class="search" src="/images/search_btn.png" alt="검색버튼"/></div>
					</div>
					<div class="filter_box prj">
						<ul>
							<li><p class="search_filter">검색 필터</p></li>
							<li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">최신 등록 순</p><span>ㅣ</span></li>
							<li><img :class="input.orderGubun == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('2')">지원 적은 순</p><span>ㅣ</span></li>
							<li><img :class="input.orderGubun == '3' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('3')">지원 많은 순</p><span>ㅣ</span></li>
							<li><img :class="input.orderGubun == '4' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('4')">마감 임박 순</p></li>
						</ul>
					</div>
				</div>

				<div class="itv-box">
					<!-- 검색 필터 부분 -->
					<div class="check_ft">
						<SearchFilter name="프로젝트 구분" v-model="input.projectDivCd" list="2:제안단계,1:수행예정" @change="getList('1', false)"/>
						<SearchFilter name="직무" cdId="PRO141" v-model="input.PRO141" @change="getList('1', false)" />
						<SearchFilter name="경력" cdId="PRO107" v-model="input.PRO107" @change="getList('1', false)"/>
						<SearchFilter name="기술등급" cdId="PRO106" v-model="input.PRO106" @change="getList('1', false)"/>
					</div>

					<!-- 모집중 & new -->
					<div v-for="project in projectList" :key="project.projectSeq" class="view int_prj box-hover">
						<div class="req">
							<div class="top">
								<!-- <div v-if="project.recrStartYyyymmdd <= nowYyyymmdd && project.recrEndYyyymmdd >= nowYyyymmdd"  class="proc">+모집중</div>
								<div v-else-if="project.recrStartYyyymmdd <= nowYyyymmdd && project.recrEndYyyymmdd < nowYyyymmdd" class="proc cp">모집마감</div> -->
			
								<div v-if="project.projectDiv == '2'" class="proc bp">제안단계</div>
                                <!-- <div v-else class="proc ep">수행예정</div> -->
								
								<div v-if="project.recrEndYyyymmdd && project.recrEndDateDiff >= 0"  class="proc">+모집중</div>
								<div v-else class="proc cp">모집마감</div>

								<div v-if="project.newYn == 'Y'" class="new">new</div>

								<div class="right">
									<div class="date">등록일자 {{project.regYyyymmdd}}</div>
								</div>
							</div>
							<div class="itv-outline int_prj_new">
								<div class="regi" style="width: 770px;">
									<div v-if="input.pageDiv == '3'" class="tit int_prj flog">공급기업 지정</div>
									<div v-else class="tit int_prj">프로젝트명</div>
									<div class="name prj-tit-hover" @click="clickProjectInfo(project.projectSeq)">{{project.projectNm}}</div>

									<div class="int_box">
										<div class="list-view int_prj">
											<div class="label ivl03">개요</div>
											<div class="list text">{{project.projectDtlDesc}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl04">고객사</div>
											<div class="list">
												<div class="add">{{project.clientNm}}</div>
												<div class="loca"><img src="/images/location.png" alt="위치" />{{project.projectLocCont}}</div>
											</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">산업분야</div>
											<div class="list" style="display: inline-block !important;"><SelectComp type="text" cdId="PRO105" :value="project.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="project.indtypeClassCd"/></div>
                                        </div>
									</div>
								</div>

								<div class="int_box">                                            
									<div class="list-view int_prj">
										<div class="label ivl04">수행사</div>
										<div class="list text">{{project.corpNm}}</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">개발기간</div>
										<div class="list">{{project.projectStartEndYyyymmdd}}</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">직무분야</div>
										<div class="list">
											<ul class="duty-list">
												<li v-for="(duty, index) in project.dutyList" :key="index">
													<span>
														<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
													</span>
												</li>
											</ul>
										</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">개발기술</div>
										<div class="list text">{{project.devTechCont}}</div>
									</div>                                            
								</div> 
							</div>
							
							<!--고객CI/지원자정보-->
							<div class="client_info">
								<div class="ci_box">
									<ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+project.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
								</div>
								<div class="deadline">
									<span v-if="project.recrEndDateDiff >= 0">
									<img class="period" src="/images/tec/prj/date.png" alt="마감기간"/> 마감 <span class="period">{{project.recrEndDateDiff}}일</span> 전
									</span>
									<img class="member" src="/images/tec/prj/member_sm.png" alt="지원자수"/> <span class="ivl06">지원자 </span><span class="member">{{project.appCount}}명</span>
								</div>
							</div>
						</div>
					</div>	

					<div v-if="pageInfo.totalRecordCount == 0" class="view int_prj">
						<div class="req none">
							관련 내용이 존재하지 않습니다!
						</div>
					</div>
				</div>

				<!--페이징 컴포넌트 -->
				<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
				<!--// 페이징 컴포넌트 -->
				
			</div>
		</div>	
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';
import SearchFilter from '@/components/base/SearchFilter.vue';


export default {

	components : {
		PagingComp ,
		SearchFilter,

	},

	data() {
		return {
			input: {
				pageIndex : '1',
				orderGubun : '1',	// 1:최신등록순, 2:지원 적은 순, 3:지원 많은 순, 4:마감 임박 순
				searchGubun : '1',	// 1:프로젝트명, 2:수행사명, 3:담당자명
				searchKeyword : '',
				projectDivCd :[],
				PRO106 : [],
				PRO107 : [],
				PRO141 : [],
				pageDiv : '',
			}, // 검색조건		

			nowYyyymmdd : '',
			projectList : [],
			pageInfo : {},
			projectCount : {},
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		if(this.$route.name == 'PRJ405M01'){
            this.input.pageDiv = '1';
        }else{
            this.input.pageDiv = '3';
        }
		
		var params = this.$route.params;

		if(params.cdId) {
			this.input.PRO141 = [params.cd];
		}
	},
	mounted() {
		// console.log('mounted');

		this.nowYyyymmdd = new Date().format("yyyyMMdd");

		if(this.$route.params != undefined && this.$route.params.pageIndex != undefined) {
			this.input = this.$route.params;
		}
		
		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList(div, isScroll = true) {
			if(div) this.input.pageIndex = 1;

			this.$.httpPost('/api/prj/sco/getScoPrjSearchList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectList	= res.data.list;
					this.pageInfo		= res.data.pageInfo;
					this.projectCount 	= res.data.projectCount;	 

					for(var i in this.projectList){
						
						if(this.projectList[i].dutyFieldCont){
							var tempArr = this.projectList[i].dutyFieldCont.split(',');
							this.projectList[i].dutyList = [];

							for(var j in tempArr){
								this.projectList[i].dutyList.push({dutyFilterCd : tempArr[j].substr(0,2),
																   dutyFieldCd  : tempArr[j]});
							}
						}

					}

					if(isScroll) {
						window.scroll(0, 0);
					}
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList('', false);
		},
		
		clickProjectInfo(projectSeq) {
			this.$router.push({name : 'PRJ405M03', query : {projectSeq : projectSeq}, params : {caller : { name : this.$route.name, params :this.input}}});
	
		},
		
		// clickTab(page){
		// 	if(this.$route.name != page){
		// 		this.$router.push({name: page});
		// 	}
			
		// },
	},
};
</script>
